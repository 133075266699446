import React from 'react';

const SingleSlide = ({ image }) => {
  return (
    <li>
      <img src={image} alt="" uk-cover="true" />
    </li>
  );
};

export default SingleSlide;
