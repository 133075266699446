import React from 'react';
import ProjectGallery from './ProjectGallery';
import introImg from '../../images/intro-img.jpeg';
import projectIllustration from '../../images/projects/project-illustration.svg';

const Projects = () => {
  return (
    <div
      className="vermiculite-intro-wrapper pb-3"
      data-aos="fade-up"
      data-aos-duration="900"
    >
      <div
        style={{ height: '200px', background: `url(${introImg}) no-repeat` }}
        className="vermiculite-intro"
      ></div>
      <div className="px-4 py-5 md:px-6 lg:px-8">
        <div
          className="flex flex-column lg:flex-row lg:align-items-center lg:justify-content-between relative"
          style={{ marginTop: '-2rem', top: '-70px', marginBottom: '-70px' }}
        >
          <div className="w-100">
            <div
              style={{
                width: '140px',
                height: '140px',
                borderRadius: '10px',
                background: 'white'
              }}
              className="mb-3 flex align-items-center justify-content-center"
            >
              <img
                src={projectIllustration}
                alt="projects"
                className="border-round"
                style={{ width: '100%', height: '100%' }}
              />
            </div>

            <div className="mb-3">
              <div className="border-bottom-1 surface-border">
                <span className="block text-3xl font-medium text-900 mb-4">
                  Projects
                </span>
              </div>
            </div>

            <p className="mt-0 mb-3 text-700 text-xl">
              <span className="green-c">Hydroponics</span> Project
            </p>
            <div className="text-600 font-medium">
              <p className="mb-3">
                <span className="font-bold">Growing media used:</span>{' '}
                Vermiculite, Peat moss, Coco peat and Perlite <br />
                <span className="font-bold">Crops tested on:</span> Lettuce,
                Thyme, Basil, Spinach, Kale, Collard greens, Chinese cabbage{' '}
                <br />
                <span className="font-bold">Flowers tested on:</span> Petunias,
                Viola-black delight, Gazania
              </p>
            </div>
            <ProjectGallery />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
