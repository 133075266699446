import * as React from 'react';

import { connect } from 'react-redux';
import Footer from '../components/Footer';
import Layout from '../components/layout';
import Projects from '../components/projects/Projects';
import Seo from '../components/seo';

const ProjectsPage = () => {
  return (
    <Layout>
      <Seo title="Projects" />
      <Projects />
      <Footer />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user
});

export default connect(mapStateToProps)(ProjectsPage);
