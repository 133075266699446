import React from 'react';
import p1 from '../../images/projects/p1.jpeg';
import p2 from '../../images/projects/p2.jpeg';
import p4 from '../../images/projects/p4.jpeg';
import p5 from '../../images/projects/p5.jpeg';
import p6 from '../../images/projects/p6.jpeg';
import p7 from '../../images/projects/p7.jpeg';
import p8 from '../../images/projects/p8.jpeg';
import p9 from '../../images/projects/p9.jpeg';
import p10 from '../../images/projects/p10.jpeg';
import p11 from '../../images/projects/p11.jpeg';
import p12 from '../../images/projects/p12.jpeg';
import p13 from '../../images/projects/p13.jpeg';
import p14 from '../../images/projects/p14.jpeg';
import SingleSlide from './SingleSlide';

const ProjectGallery = () => {
  return (
    <div
      className="uk-position-relative uk-visible-toggle uk-light"
      tabIndex="-1"
      uk-slideshow="animation: pull;finite: true"
    >
      <ul className="uk-slideshow-items w-100" uk-height-viewport="offset-bottom: -15">
        <SingleSlide image={p4} />
        <SingleSlide image={p2} />
        <SingleSlide image={p1} />
        <SingleSlide image={p12} />
        <SingleSlide image={p11} />
        <SingleSlide image={p6} />
        <SingleSlide image={p7} />
        <SingleSlide image={p8} />
        <SingleSlide image={p5} />
        <SingleSlide image={p9} />
        <SingleSlide image={p10} />
        <SingleSlide image={p13} />
        <SingleSlide image={p14} />
      </ul>
      <a
        className="uk-position-center-left uk-position-small uk-hidden-hover"
        href="#"
        uk-slidenav-previous="true"
        uk-slideshow-item="previous"
      ></a>
      <a
        className="uk-position-center-right uk-position-small uk-hidden-hover"
        href="#"
        uk-slidenav-next="true"
        uk-slideshow-item="next"
      ></a>
    </div>
  );
};

export default ProjectGallery;
